import Clarity from 'react-microsoft-clarity';
import {CLARITY_ID} from '@/constants/global';

let clarity: typeof Clarity.clarity | null = null;

export function getClarity() {
  if (typeof window !== 'undefined' && !clarity) {
    clarity = Clarity.clarity;
    clarity.init(CLARITY_ID);
  }

  return clarity;
}
