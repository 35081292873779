import {Icon, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useRef, useState, useCallback, useEffect, useMemo} from 'react';
import {useIsFirstRender} from 'usehooks-ts'; // NOSONAR this is deprecated
import EmailChip from './EmailChip';
import {PracticeUserDto} from '@/models';
import {validateEmail, validateEmailDomain} from '@/utils/validations';

const ERRORS = {
  validDomain: 'Email must match practice email.',
  validAlreadyInvited: 'Person already invited to your practice.',
  validAlreadyExist: 'Person already exists at your practice.',
};

export interface IEmailGroupInputProps {
  emails: string[];
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
  placeholder?: string;
  domain?: string;
  onChange?: (_emails: string[], _error: boolean) => void;
  existingEmails?: Pick<PracticeUserDto, 'email' | 'inviteAccepted'>[];
}

const EmailGroupInput: FC<IEmailGroupInputProps> = ({
  emails,
  setEmails,
  placeholder,
  domain,
  onChange,
  existingEmails,
}) => {
  const [validEmails, setValidEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState('');
  const isFirstRender = useIsFirstRender(); // NOSONAR this is deprecated
  const inputRef = useRef<HTMLInputElement>(null);

  const showPlaceHolder = useMemo(
    () => placeholder && !isFocused && emails?.length === 0 && !inputValue,
    [placeholder, isFocused, emails, inputValue],
  );

  const handleContainerClick = () => {
    inputRef.current?.focus();
  };

  const isActive = useCallback(
    (email: string) =>
      existingEmails?.some(
        (existingEmail) =>
          existingEmail.email === email && existingEmail.inviteAccepted,
      ),
    [existingEmails],
  );

  // Check that the email doesn't exist in the pool
  const checkEmail = useCallback(
    (email: string) =>
      !emails.includes(email) &&
      !existingEmails?.some((existingEmail) => existingEmail.email === email),
    [emails, existingEmails],
  );

  const setNewEmails = useCallback(
    (newEmails: string[]) => {
      // Check if after setting the new emails array, there are still wrong emails
      if (domain) {
        if (
          newEmails.every(
            (email) =>
              validateEmailDomain(email, domain) &&
              validateEmail(email) &&
              checkEmail(email),
          )
        ) {
          setValidEmails(newEmails);
          setError('');
        } else if (newEmails.every((email) => !checkEmail(email))) {
          if (newEmails.some((email) => isActive(email))) {
            setError(ERRORS.validAlreadyExist); //was invited and active
          } else {
            setError(ERRORS.validAlreadyInvited); //was invited but not active
          }
        } else {
          setError(ERRORS.validDomain);
        }
      }
      setEmails(newEmails);
    },
    [domain, checkEmail, isActive, setEmails],
  );

  const addEmail = useCallback(() => {
    if (inputValue === '') {
      return;
    }
    setNewEmails([...emails, inputValue]);
    setInputValue('');
  }, [inputValue, emails, setNewEmails]);

  const removeEmail = useCallback(
    (index: number) => {
      setNewEmails([...emails.slice(0, index), ...emails.slice(index + 1)]);
    },
    [emails, setNewEmails],
  );

  useEffect(() => {
    if (!isFirstRender && onChange) {
      onChange(validEmails, !!error);
    }
  }, [isFirstRender, validEmails, onChange, error]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (/[, ]+/.test(value)) {
        return;
      }
      setInputValue(value);
    },
    [],
  );

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Enter':
        case 'Tab':
          e.preventDefault();
          break;
        case 'Backspace':
          if (!e.currentTarget.value) {
            removeEmail(emails?.length - 1);
          }
          break;
        default:
      }
    },
    [emails, removeEmail],
  );

  const handleInputKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Enter':
        case 'Tab':
        case ' ':
        case ',':
          addEmail();
          break;
        default:
      }
    },
    [addEmail],
  );

  return (
    <div className='flex flex-col space-y-1'>
      <button
        className={clsx(
          'flex items-center justify-between space-x-2 rounded-sm border border-solid bg-transparent px-3 py-2',
          {
            'border-error': error,
            'border-theme-primary': isFocused && !error,
            'border-neutral-darker/56': !isFocused && !error,
          },
        )}
        onClick={handleContainerClick}>
        <div className='flex w-full flex-wrap items-center gap-2'>
          {emails.map((email, idx) => (
            <EmailChip
              key={`email-group-item-${email}`}
              onDelete={() => {
                removeEmail(idx);
              }}
              error={domain ? !validateEmailDomain(email, domain) : false}>
              {email}
            </EmailChip>
          ))}
          <input
            data-testid='email_group_input'
            data-clarity-unmask='true'
            ref={inputRef}
            placeholder={showPlaceHolder ? placeholder : ''}
            className={clsx(
              'flex min-h-[1.5rem] flex-1 border-0 px-1 text-sm font-medium text-neutral-dark caret-current outline-none placeholder:text-sm placeholder:text-neutral-secondary',
            )}
            type='text'
            value={inputValue}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onKeyUp={handleInputKeyUp}
          />
        </div>
        <div className='flex flex-nowrap items-center space-x-2 whitespace-nowrap text-neutral-dark'>
          <Text as='body-xs' weight='bold'>
            Can edit
          </Text>
          <Icon name='CheckmarkFilled' className='flex w-2.5 items-center' />
        </div>
      </button>
      {error ? (
        <Text size='xs' weight='regular' variant='error'>
          {error}
        </Text>
      ) : null}
    </div>
  );
};

export default EmailGroupInput;
