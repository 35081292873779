import {Text, TextVariant} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {NewLinkType} from './ResourceButton';
import {linkTypes} from '@/constants/resourceButton';
import {formatToYearMonth} from '@/utils/date';
import {convertResourceType} from '@/utils/resourceButtonHelpers';

interface ResourceTypeProps {
  isPubMedFeature: boolean;
  date?: string;
  variant: TextVariant;
  type: NewLinkType;
}

const ResourceType = ({
  isPubMedFeature,
  date,
  variant,
  type,
}: ResourceTypeProps) =>
  isPubMedFeature ? (
    <span className='flex flex-wrap items-center'>
      <Text
        as='body-xs'
        weight='bold'
        casing='caps'
        className='text-blue-700'
        variant={variant}>
        {type}
      </Text>
      <span className='mx-1 h-1 w-1 rounded-full bg-neutral-darker/20' />
      {date ? (
        <Text
          as='body-xs'
          weight='bold'
          casing='caps'
          className='text-blue-700'
          variant={variant}>
          {formatToYearMonth(date)}
        </Text>
      ) : null}
    </span>
  ) : (
    <span className='flex flex-wrap items-center'>
      <p
        className={clsx(
          'm-0 mr-1 h-1 w-1 rounded text-neutral-primary',
          linkTypes[type],
        )}
      />
      <Text as='body-xs' weight='bold' casing='caps' variant={variant}>
        {convertResourceType(type)}
      </Text>
    </span>
  );

export default ResourceType;
