import {FC} from 'react';
import Header from './Header';
import Results from './Results';
import {DrugSearchResult} from '@/interfaces';
import {WrapperInstantSearch} from '@/services/WrapperInstantSearch';

export interface ISingleBrandSelectProps {
  onBackClick?: () => void;
  onSelectDrug?: (_drug: DrugSearchResult) => void;
}

const SingleBrandSearch: FC<ISingleBrandSelectProps> = ({
  onBackClick = () => null,
  onSelectDrug = () => null,
}) => (
  <div className='px-6 py-4 md:py-10'>
    <WrapperInstantSearch hitsPerPage={500} analitycs={false}>
      <div className='sticky top-0 bg-white'>
        <Header onBackClick={onBackClick} />
      </div>
      <Results onDrugSelect={onSelectDrug} />
    </WrapperInstantSearch>
  </div>
);

export default SingleBrandSearch;
