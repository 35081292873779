import {
  Icon,
  Text,
  Modal,
  IconButton,
  Portal,
  Button,
} from '@prescriberpoint/ui';
import {FC, useCallback, useEffect, useState} from 'react';
import StepForm, {IStep} from '../StepForm';
import {useUserAgentContext} from '@/context';

export interface IAssignResourceModalFormProps {
  form: IStep[];
  isOpen: boolean;
  titleLabel: string;
  title: string;
  submitText?: string;
  onCloseClick?: () => void;
  onSubmit?: (_data: any) => Promise<void>;
}

const AssignResourceModalForm: FC<IAssignResourceModalFormProps> = ({
  form,
  isOpen,
  titleLabel,
  title,
  submitText = 'Assign & Share',
  onCloseClick = () => null,
  onSubmit,
}) => {
  const [showConfirmationStep, setShowConfirmationStep] = useState(false);
  const {isMobileOrTablet} = useUserAgentContext();

  useEffect(() => {
    if (isOpen) {
      setShowConfirmationStep(false);
    }
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (formData: any) => {
      try {
        if (onSubmit) {
          await onSubmit(formData);
        }
        setShowConfirmationStep(true);
      } catch (error) {
        console.log(error);
        setShowConfirmationStep(false);
      }
    },
    [onSubmit],
  );

  const renderContent = useCallback(
    () =>
      showConfirmationStep ? (
        <div className='flex h-full w-full flex-col justify-center bg-white px-6 py-10 md:h-[646px]'>
          <div className='flex flex-1 items-center justify-center'>
            <Text as='headline-lg' weight='bold'>
              Check your email
            </Text>
          </div>
          <Button id='close_modal' size='xl' stretched onClick={onCloseClick}>
            Close
          </Button>
        </div>
      ) : (
        <div className='flex h-full w-full flex-col space-y-6 bg-white px-6 py-4'>
          <div className='flex items-start justify-between'>
            <div className='flex flex-1 flex-col md:w-[calc(100%-64px)]'>
              <Text
                as='body-xs'
                weight='bold'
                casing='caps'
                className='self-start bg-neutral-lighter p-1'>
                {titleLabel}
              </Text>
              <Text
                as='title-lg'
                casing='title'
                className='w-[calc(100vw-88px)] overflow-hidden text-ellipsis md:w-full'>
                {title}
              </Text>
            </div>
            <div>
              <IconButton
                onClick={onCloseClick}
                id='close_assign_resource_modal'
                icon={
                  <Icon
                    name='Cancel'
                    className='flex h-4 w-4 items-center text-theme-primary'
                  />
                }
              />
            </div>
          </div>
          <StepForm
            stepsForm={form}
            onSubmit={handleSubmit}
            submitBtnText={submitText}
            disclaimerText='You accept our terms and conditions'
          />
        </div>
      ),
    [
      titleLabel,
      title,
      handleSubmit,
      onCloseClick,
      form,
      submitText,
      showConfirmationStep,
    ],
  );

  return isMobileOrTablet ? (
    isOpen && (
      <Portal>
        <div className='fixed inset-0 z-max overflow-y-auto bg-white pb-2'>
          {renderContent()}
        </div>
      </Portal>
    )
  ) : (
    <Modal
      className='!w-[488px] !max-w-[488px] md:h-max md:max-h-[98%]'
      isOpen={isOpen}
      closeModal={onCloseClick}
      showCloseButton={false}
      showCancel={false}
      showConfirm={false}>
      {renderContent()}
    </Modal>
  );
};

export default AssignResourceModalForm;
