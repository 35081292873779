import {FormInput} from '@prescriberpoint/ui';
import {FC, useState, useCallback, useMemo} from 'react';
import {IFormInput} from '.';

export interface GenericInputProps {
  formInput: IFormInput;
  disabled?: boolean;
  setFormValue: (
    _id: string,
    _value: string,
    _valid: boolean,
    _required?: boolean,
  ) => void;
}

const GenericInput: FC<GenericInputProps> = ({
  formInput,
  disabled = false,
  setFormValue,
}) => {
  const [value, setValue] = useState(formInput.defaultValue ?? '');
  const [checked, setChecked] = useState(!!formInput?.checked);
  const [error, setError] = useState<React.ReactNode>(null);
  const [valid, setValid] = useState(true);
  const [showStillError, setShowStillError] = useState(false);

  const onValueChange = useCallback(
    (v: any) => {
      const valid = formInput.validateFn(v);
      if (formInput.type !== 'check') {
        setValue(v);
      } else {
        setChecked(!!v);
      }
      setFormValue(formInput.id, v, valid, formInput.required);
      setError('');
    },
    [formInput, setFormValue],
  );

  const onInputFocus = useCallback(() => {
    // Always show the password validation checks after the input has been focused
    if (formInput.type === 'password') {
      setShowStillError(true);
    }
  }, [formInput.type]);

  const onInputBlur = useCallback(() => {
    const isValid =
      formInput.validateFn(value) || (!formInput.required && !value);

    setValid(isValid);
    if (!isValid && (formInput.required || !!value)) {
      setError(formInput.errorMessage || 'Please enter a valid value.');
    } else {
      setError('');
    }
  }, [formInput, value, setValid]);

  const errorMessageComponent = useMemo(() => {
    if (formInput.errorComponent && (error || formInput.type === 'password')) {
      return formInput.errorComponent(value, showStillError);
    }
    return error;
  }, [error, formInput, showStillError, value]);

  return (
    <FormInput
      disabled={disabled}
      errorMessage={errorMessageComponent}
      id={formInput.id}
      name={formInput.name}
      autoComplete={formInput.type}
      label={formInput.label}
      inputSize={formInput.inputSize}
      onBlur={onInputBlur}
      onFocus={onInputFocus}
      onChange={onValueChange}
      placeholder={formInput?.placeholder}
      required={formInput.required}
      type={formInput.type}
      value={value}
      checked={checked}
      options={formInput.options}
      isValid={valid}
      labelClassNames={formInput.labelClassName}
      containerClassName={formInput.containerClassName}
      className={formInput.className}
      defaultValue={formInput.defaultValue}
    />
  );
};

export default GenericInput;
