import {Button, Text} from '@prescriberpoint/ui';
import {BlobProvider} from '@react-pdf/renderer';
import {FC} from 'react';
import QRCode from 'react-qr-code';
import QRCodeDocument from './QRCodeDocument';

interface RepRegistryQrProps {
  url: string;
  show: boolean;
  setShow: (_value: boolean) => void;
  platform?: 'mobile' | 'desktop';
}

const STEPS = [
  'Print your QR code',
  'Put on front desk',
  'Scanned by rep to check in',
  'Get notified each time',
];

const RepRegistryQr: FC<RepRegistryQrProps> = ({
  url,
  show,
  setShow,
  platform = 'desktop',
}) => {
  const onIframeLoad = () => {
    // @ts-ignore
    const pdfFrame = window.frames['pdf'];
    pdfFrame.focus();
    setTimeout(() => {
      pdfFrame.print();
    }, 500);
  };

  return (
    <div>
      <div className='flex justify-center'>
        <div id='code' className='w-[263px] bg-white p-5'>
          <QRCode
            id={`qr_code_${platform}`}
            size={256}
            style={{height: 'auto', maxWidth: '100%', width: '100%'}}
            qr-title={url}
            value={url}
            // @ts-ignore
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>

      <div className='pb-3'>
        <Text as='title-md'>View & monitor rep activity</Text>
      </div>
      <div className='space-y-2'>
        {STEPS.map((value, index) => (
          <div className='flex items-center space-x-2' key={value}>
            <Text
              as='body-md'
              className='flex h-5 w-5 items-center justify-center rounded-full bg-theme-primary !text-white'
              tag='div'>
              {index + 1}
            </Text>
            <Text as='body-md'>{value}</Text>
          </div>
        ))}
      </div>
      <div className='space-y-4 pb-4 pt-6'>
        <BlobProvider document={<QRCodeDocument id={`qr_code_${platform}`} />}>
          {({blob}: any) => (
            <Button
              onClick={() => {
                const iframe = document.createElement('iframe');
                iframe.src = window.URL.createObjectURL(blob);
                iframe.name = 'pdf';
                iframe.hidden = true;
                iframe.onload = onIframeLoad;
                document.body.appendChild(iframe);
              }}
              stretched
              id={`print_qr_code_${platform}`}>
              Print QR Code
            </Button>
          )}
        </BlobProvider>
        {show ? (
          <Button
            id={`dont_show_qr_${platform}`}
            variant='secondary'
            onClick={() => setShow(false)}
            stretched>
            Don&apos;t show this message again
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default RepRegistryQr;
