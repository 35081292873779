import {Text, Button, customEvent} from '@prescriberpoint/ui';
import {SearchIcon} from 'lucide-react';
import {FC, useMemo} from 'react';
import {useCookies} from 'react-cookie';
import {coverageSearch} from '../CoverageSummarySearch/CoveragePayerForm/coverageSearch';
import CoverageEmployerInput from './CoverageEmployerInput';
import {CEVENT_COVERAGE_SEARCH} from '@/constants';
import {NPI_COOKIE} from '@/constants/cookies';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useCoverageRestrictionsContext, useUserAgentContext} from '@/context';
import {useCurrentDrug} from '@/hooks';
import {useStepTherapyLogMutation} from '@/mutations';

interface ICoverageBodyProps {
  onPdp: boolean;
}

const CoverageEmployerForm: FC<ICoverageBodyProps> = ({onPdp}) => {
  const [cookies] = useCookies([NPI_COOKIE]);
  const {setId, drugName, genericName} = useCurrentDrug();
  const npi = useMemo(() => cookies?.npi, [cookies]);
  const {mutateAsync: stepTherapyMutate} = useStepTherapyLogMutation();
  const {isMobileOrTablet} = useUserAgentContext();
  const {
    selectedIndication,
    selectedDosage,
    setCoverageRestrictionTitle,
    setSubmittedCoverage,
    setResult,
    isSubmitting,
    selectedEmployer,
    showEmployerForm,
    setIsSubmitting,
    setShowEmployerForm,
    setFormErrors,
    isForYou,
  } = useCoverageRestrictionsContext();

  const scrollOnNewSearch = () => {
    if (onPdp) {
      document.getElementById(SECTION_IDS.coverage)?.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  };

  const searchByEmployer = async () => {
    if (!selectedEmployer) {
      setFormErrors({
        employer: "Employer name can't be blank",
      });
      return;
    }

    if (selectedIndication && selectedDosage && !isSubmitting) {
      scrollOnNewSearch();
      setResult(undefined);
      setIsSubmitting(true);
      setShowEmployerForm(true);
      const indicationId = selectedIndication.id.toString() ?? '';
      const dosageId = selectedDosage.id.toString() ?? '';
      const {
        planTypeName,
        channelName,
        mcoName,
        value: employerName,
      } = selectedEmployer;
      const dataToSubmit = {
        mco: selectedEmployer.mcoId,
        planType: selectedEmployer.planTypeId.toString(),
        channel: selectedEmployer.channelId.toString(),
        indicationId,
        setId,
        rxcui: dosageId,
      };
      const coverageResult = await coverageSearch(dataToSubmit);
      customEvent(CEVENT_COVERAGE_SEARCH, {
        employer: employerName,
        mco: dataToSubmit.mco,
        channel: dataToSubmit.channel,
        planType: dataToSubmit.planType,
        npiValue: npi ?? null,
      });

      const formularyId = coverageResult?.topFormularyDetail?.FormularyId;

      if (coverageResult && formularyId) {
        const {
          priorAuthorization,
          quantityLimit,
          stepTherapy,
          specialtyPharmacy,
        } = coverageResult.coverageInsights;
        const carrier = coverageResult.coverageRestrictions;

        setResult({
          employer: employerName,
          payerName: coverageResult.mcoCoverages[0].MCO,
          planType: planTypeName,
          channel: channelName,
          carrier,
          formularyId,
          hasPaRestrictions: priorAuthorization,
          hasStepTherapy: stepTherapy,
          hasQuantityLimits: quantityLimit,
          hasSpecialtyPharmacy: specialtyPharmacy,
        });
      } else {
        setResult({
          employer: employerName,
          payerName: mcoName,
          channel: channelName,
          planType: planTypeName,
        });
      }

      const stepTherapyMutateData = {
        formularyIds:
          coverageResult?.formularies.map((f) => +f.formularyId) ?? undefined,
        brand: drugName,
        indication: selectedIndication.text,
        indicationId: `${selectedIndication.id}`,
        dosage: selectedDosage.text,
        dosageId: selectedDosage.id,
        npi: npi ?? '',
        chosenFormularyId: formularyId ?? null,
        carrier: mcoName,
        insuranceChannel: channelName,
        genericName,
      };

      if (npi) {
        await stepTherapyMutate(stepTherapyMutateData);
      }

      setSubmittedCoverage(stepTherapyMutateData);
      setCoverageRestrictionTitle(`${employerName} (${mcoName})`);
      setIsSubmitting(false);
    }
  };

  if (showEmployerForm || isForYou) {
    return null;
  }

  return (
    <div className='w-full rounded-lg bg-green-50 p-5'>
      <div className='flex w-full flex-row md:space-x-5'>
        <SearchIcon
          size={24}
          className='hidden text-neutral-primary md:block'
        />
        <div className='flex w-full flex-col space-y-5'>
          <div className='flex flex-col space-y-1'>
            <Text as='body-md' weight='bold'>
              Some employers negotiate directly with insurance and PBM&apos;s on
              their employee&apos;s behalf.
            </Text>
            <Text as='body-sm'>
              Enter the name of the employer to see if there are any additional
              requirements.
            </Text>
            {/* <Link
              data-testid='employer-search-link'
              type='underline'
              linkSize='sm'
              color='primary'
              onClick={() => setShowInput(true)}
              label='See if your patient has different requirements based on their employer'
            /> */}
          </div>
          <div className='flex w-full flex-col items-start space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0'>
            <CoverageEmployerInput />
            <Button
              id='search-by-employer'
              testId='search-by-employer_button'
              size='lg'
              variant='alternative'
              onClick={searchByEmployer}
              stretched={isMobileOrTablet}>
              Check Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverageEmployerForm;
