import {Icon, IconButton} from '@prescriberpoint/ui';
import clsx from 'clsx';
import React, {FC} from 'react';
import {NewLinkType, ResourceButtonType} from './ResourceButton';
import {actionIconKeys} from '@/constants/resourceButton';
import {useRenderInIFrame} from '@/hooks';
interface ResourceActionsProps {
  buttonType?: ResourceButtonType;
  debounced: (_cb: any) => void;
  disabled?: boolean;
  disabledStyle: boolean;
  handleActionClick: () => void;
  handleLinkClick: () => void;
  handleShareClick: () => void;
  id: string;
  type: NewLinkType;
  showDownloadAtLeft?: boolean;
}

const ResourceActions: FC<ResourceActionsProps> = ({
  buttonType = 'redirect',
  debounced,
  disabled,
  disabledStyle,
  handleActionClick,
  handleLinkClick,
  handleShareClick,
  id,
  type,
  showDownloadAtLeft = false,
}) => {
  const pdpIsEmbedded = useRenderInIFrame();
  const actionIcon = actionIconKeys[buttonType] ?? actionIconKeys['unknown'];
  const isPubMedFeature = [
    'resourceTypeClinicalTrial',
    'resourceTypeSystemicReview',
    'Journal Article',
    'Classical Article',
    'Review',
    'English Abstract',
    'Meta-Analysis',
  ].includes(type);
  return (
    <div className='flex items-center space-x-2'>
      {buttonType !== 'unknown' && !isPubMedFeature ? (
        <IconButton
          id={`${id}_action`}
          onClick={() =>
            buttonType === 'recent'
              ? debounced(handleLinkClick())
              : debounced(handleActionClick())
          }
          className='!h-9 !w-9'
          disabled={disabled}
          icon={
            <Icon
              className={clsx(
                'h-5 w-5 rounded-full',
                disabledStyle ? 'text-neutral-tertiary' : 'text-theme-primary',
              )}
              name={actionIcon.name}
            />
          }
          tooltip={actionIcon.tooltip}
        />
      ) : null}

      {!pdpIsEmbedded && !isPubMedFeature && !showDownloadAtLeft ? (
        <IconButton
          id={`${id}_share`}
          onClick={() => debounced(handleShareClick())}
          disabled={disabled}
          icon={
            <Icon
              className={clsx(
                'h-5 w-5 rounded-full',
                disabledStyle ? 'text-neutral-tertiary' : 'text-theme-primary',
              )}
              name='ChatMailRegular'
            />
          }
          tooltip='Share'
        />
      ) : null}
    </div>
  );
};

export default ResourceActions;
