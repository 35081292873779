// eslint-disable-next-line import/named
import {useCookies} from 'react-cookie';
// eslint-disable-next-line import/named
import {CookieSetOptions} from 'universal-cookie';
import {COOKIE_CONSENT_COOKIE, NECESSARY_COOKIES} from '@/constants/cookies';

export const useHandleSetCookies = () => {
  const [cookies, setCookie] = useCookies();
  const hasConsent = cookies[COOKIE_CONSENT_COOKIE] !== 'false';
  const isSecure =
    typeof window !== 'undefined' && window.location.protocol === 'https:';

  const handleSetCookie = (
    name: string,
    value: any,
    options?: CookieSetOptions | undefined,
    callback?: (_hasConsent: boolean) => void,
  ) => {
    if (hasConsent || NECESSARY_COOKIES.includes(name)) {
      setCookie(name, value, {
        ...options,
        sameSite: 'strict',
        secure: isSecure,
      });
    }
    if (callback) {
      callback?.(hasConsent);
    }
  };

  return {handleSetCookie};
};
