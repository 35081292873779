import {Text, StatusTag} from '@prescriberpoint/ui';
import clsx from 'clsx';
import ResourceList from './ResourceList';
import {Icon, List} from '@/components';
import {LucideIconNameType} from '@/components/Icon';
import {useUserAgentContext} from '@/context';
import useFinancialAssistanceResources from '@/hooks/useFinancialAssistance';
import {EnhancedContentLinkDto, RecentDocumentDto} from '@/models';

export interface IAvailableProgram {
  name: string;
  icon: string;
}

export interface ProgramSectionProps {
  title: string;
  programs?: IAvailableProgram[];
  chipInfo?: string;
  details?: string[];
  requirements?: string[];
  resources: Array<EnhancedContentLinkDto | RecentDocumentDto>;
  sectionId: string;
}

const ProgramSection = ({
  title,
  programs,
  chipInfo,
  details = [],
  requirements = [],
  resources,
  sectionId,
}: ProgramSectionProps) => {
  const sectionResources = useFinancialAssistanceResources(
    resources,
    sectionId,
  );
  const {isMobileOrTablet} = useUserAgentContext();

  if (sectionResources.length === 0) return null;

  return (
    <article
      className={clsx(
        'flex flex-col gap-y-3 rounded-xl border border-solid border-neutral-light bg-white p-4 sm:gap-y-4 sm:p-6',
      )}>
      <div className='flex flex-col gap-y-1'>
        <Text
          as={isMobileOrTablet ? 'title-md' : 'headline-sm'}
          casing='title'
          weight={isMobileOrTablet ? 'extrabold' : 'bold'}>
          {title}
        </Text>
        {chipInfo ? (
          <StatusTag type='success' label={chipInfo} size='lg' />
        ) : null}
      </div>

      {programs ? (
        <div className='flex flex-col gap-y-2'>
          <Text
            as={isMobileOrTablet ? 'body-sm' : 'body-md'}
            weight='extrabold'>
            Available for
          </Text>
          <div className='flex w-full items-center gap-x-4'>
            {programs.map(({name, icon}) => (
              <div
                className='flex w-[166px] items-center gap-x-2'
                key={`${title}program:${name}`}>
                <div className='flex items-center p-1.5'>
                  <Icon
                    className='size-5 text-neutral-primary'
                    name={icon as LucideIconNameType}
                  />
                </div>
                <Text as='body-md' casing='title' variant='secondary'>
                  {name}
                </Text>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <ResourceList resources={sectionResources} />
      <List
        title='Program Details'
        textClassName='font-normal'
        titleType={isMobileOrTablet ? 'body-sm' : 'body-md'}
        items={details}
      />
      <List
        title='Eligibility requirements'
        textClassName='font-normal'
        titleType={isMobileOrTablet ? 'body-sm' : 'body-md'}
        items={requirements}
      />
    </article>
  );
};

export default ProgramSection;
