import {useMemo} from 'react';
import {useCurrentSlug} from '../useCurrentSlug';
import {useShowDosageSection} from './useShowDosageSection';
import {useShowEnhancedSection} from './useShowEnhancedSection';
import {useShowFASection} from './useShowFASection';
import {useShowPASection} from './useShowPASection';
import {PATIENT_EDUCATION_SECTION} from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';

// TODO we need to continue this,
// to have in one place the status of the sections

export function useSectionsStatus() {
  const {setId} = useCurrentSlug();

  const showPA = useShowPASection(setId);
  const showPE = useShowEnhancedSection(
    SECTION_IDS.patientEducation,
    PATIENT_EDUCATION_SECTION,
  );
  const showFA = useShowFASection(setId);

  const showDosage = useShowDosageSection(setId);

  const sections = useMemo(
    () => ({
      summary: showDosage, // dosages and administration
      financialAssistance: showFA, // financial assistance
      priorAuthorization: showPA, // prior authorization
      patientEducation: showPE, // patient education
    }),
    [showFA, showPA, showPE, showDosage],
  );

  return sections;
}
