import {MenuListItem} from '@prescriberpoint/ui';
import {FC} from 'react';
import {RegistrationModalNew} from '@/components/RegistrationModal';
interface IFavoriteStepProps {
  step: number;
  onChangeStep: (_step: number) => void;
  onClose: () => void;
  isOpen: boolean;
}

const FavoriteStep: FC<IFavoriteStepProps> = ({
  step,
  onChangeStep,
  onClose,
  isOpen,
}) => {
  const handleSubmit = () => {
    onChangeStep(3);
  };

  return (
    <div className='flex flex-col pb-6'>
      <MenuListItem
        type='titleSeparator'
        content={step === 2 ? 'Create a Free Account' : 'Check Your Email'}
        className='pl-0'
      />
      <RegistrationModalNew
        isOpenStatus={isOpen}
        onlyContent
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </div>
  );
};

export default FavoriteStep;
