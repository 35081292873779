import {Text, useIsMobileOrTablet} from '@prescriberpoint/ui';
import clsx from 'clsx';
import React from 'react';
import StepTherapyStep from './StepTherapyStep';
import {COVERAGE_V3} from '@/constants/flags';
import {useCoverageRestrictionsContext, useFlag} from '@/context';
import {CoverageStepModel} from '@/models/coverageModel';

interface IStepTherapyStepProps {
  steps: CoverageStepModel[];
  className?: string;
}

const StepTherapyStepList = ({steps, className}: IStepTherapyStepProps) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const showCoverageV3 = useFlag(COVERAGE_V3);
  const {isForYou} = useCoverageRestrictionsContext();

  if (steps.length === 0) return null;

  const totalDependenciesCount = steps.reduce(
    (acc, step) => acc + step.quantity,
    0,
  );

  return (
    <div data-testid='step-therapy-card-list' className={className}>
      <Text
        as={isMobileOrTablet ? 'title-md' : 'title-lg'}
        weight='extrabold'
        className={clsx('!leading-4.5 block', {
          'text-base md:text-lg': isForYou,
        })}>
        Step Therapy
      </Text>
      <Text
        as={isMobileOrTablet ? 'body-md' : 'title-md'}
        weight='semibold'
        className='mb-6 block text-neutral-darker md:mb-4'>
        {`Must have tried and failed ${totalDependenciesCount} ${
          totalDependenciesCount > 1 ? 'drugs' : 'drug'
        }`}
      </Text>

      {steps.map((step, idx) => (
        <div key={`step-therapy-${step.therapies}`} className='relative'>
          <StepTherapyStep
            isLastStep={idx === steps.length - 1}
            dependenciesCount={step.quantity}
            requirements={step.therapies}
          />

          {!['NONE'].includes(step.connector) && (
            <div
              className={clsx('absolute bottom-3 rounded-lg px-2 py-0.5', [
                showCoverageV3 ? 'bg-neutral-light' : 'bg-theme-primary',
              ])}>
              <Text
                as='body-sm'
                weight='bold'
                className={clsx(
                  'block leading-none',
                  [showCoverageV3 ? 'text-neutral-primary' : 'text-white'],
                  {
                    'text-xs': isForYou,
                  },
                )}>
                {step.connector}
              </Text>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default StepTherapyStepList;
