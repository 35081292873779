import {CTAForm, Text, ModalNotification, Button} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {CheckIcon, KeyIcon} from 'lucide-react';
import {FC, useEffect, useState} from 'react';
import {useLocalStorage} from 'usehooks-ts';
import AgreeTermsAndCondtitionsAndPrivacyPolicy from '../Auth/AddEmail/AgreeTermsAndCondtitionsAndPrivacyPolicy';
import AuthFooter, {FooterCTA} from '../Auth/AuthFooter';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {useError} from '@/hooks';
import {useAuthPath} from '@/hooks/useAuthPath';
import {useAddEmail} from '@/mutations/useAddEmail';
import {APIError} from '@/services/request';
import {validateEmail, validateTextPresence} from '@/utils/validations';

export interface IRegistrationModalNewProps {
  redirectUrl?: string;
  customEvent?: (_email: string) => void;
  onClose?: () => void;
  onlyContent?: boolean;
  onSubmit?: () => void;
  isOpenStatus?: boolean;
}

const RegistrationModalNew: FC<IRegistrationModalNewProps> = ({
  redirectUrl,
  customEvent,
  onClose = () => {},
  onlyContent = false,
  onSubmit,
  isOpenStatus = false,
}) => {
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const {loginPath} = useAuthPath();
  const [isOpen, setIsOpen] = useState(isOpenStatus);
  const [submitCompleted, setSubmitCompleted] = useState(false);
  const {mutateAsync: register, error} = useAddEmail();
  const [_, setPaywall] = useLocalStorage('user_submitted_paywall', false);
  const {errorMsg} = useError(error as APIError | undefined);

  const {isMobileOrTablet} = useUserAgentContext();

  const footerCTAs: FooterCTA[] = [
    {
      label: 'Already have an account?',
      href: loginPath + `/?redirect=${window.location.pathname}`,
      button: 'Log in',
    },
  ];

  useEffect(() => {
    if (error) {
      // @ts-ignore
      if (error.code === 409) {
        setInvalidEmailError(true);
      } else {
        setInvalidEmailError(false);
      }
    }
  }, [error, isMobileOrTablet]);

  useEffect(() => {
    if (!isOpen) {
      onClose && onClose();
    }
  }, [isOpen, onClose]);

  const handleSubmit = async (data: any) => {
    try {
      await register(data);
      customEvent && customEvent(data.email);
      setPaywall(true);
      setSubmitCompleted(true);
      onSubmit && onSubmit();
      if (redirectUrl) {
        window.open(redirectUrl, '_blank');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderContent = () =>
    !submitCompleted ? (
      <div className='flex flex-col space-y-5'>
        <Text as='body-md' className='text-left md:text-center'>
          Save this brand and other FDA-approved drug labels to view later
        </Text>
        <CTAForm
          form={{
            submitText: 'Create an Account',
            isSubmittingText: 'Submitting...',
            inputs: [
              {
                id: 'email',
                type: 'email',
                label: 'Email',
                required: true,
                placeholder: 'Your work email',
                errorMessage: 'Invalid email.',
                containerClassName: '!text-left',
                validateFn: (v: string) =>
                  validateEmail(v) && validateTextPresence(v),
              },
            ],
          }}
          onSubmit={handleSubmit}
          submitError={!!error}
          submitErrorMsg={invalidEmailError ? 'Invalid email.' : errorMsg}
          buttonSize='lg'
          buttonVariant='alternative'
        />
        <div className='flex flex-col items-start justify-start space-y-2'>
          <AuthFooter as='body-sm' linkSize='sm' callToActions={footerCTAs} />
          <AgreeTermsAndCondtitionsAndPrivacyPolicy
            as='body-sm'
            linkSize='sm'
          />
        </div>
      </div>
    ) : (
      <div className='flex flex-col space-y-5'>
        <Text as='body-md' className='text-left md:text-center'>
          Thank you, please check your email for your account verification link.
        </Text>
        <Button
          id='close-modal'
          stretched
          variant='alternative'
          onClick={handleClose}
          className={clsx({'-mx-1': !onlyContent})}>
          Close
        </Button>
      </div>
    );

  const handleClose = () => {
    setIsOpen(false);
  };

  if (onlyContent) {
    return renderContent();
  }

  return (
    <ModalNotification
      customIcon={
        submitCompleted ? (
          <CheckIcon
            size={30}
            strokeWidth={1.6}
            className='text-theme-primary'
          />
        ) : (
          <KeyIcon size={30} strokeWidth={1.6} className='text-theme-primary' />
        )
      }
      isOpen={isOpen}
      showCloseButton
      closeModal={handleClose}
      actionMessage={
        submitCompleted ? 'Check Your Email' : 'Create a Free Account'
      }
      bodyClassName='pb-6'
      showCta={false}
      actionContext={renderContent()}
      type='email'
      position='center'
    />
  );
};

export default RegistrationModalNew;
