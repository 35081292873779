import {useState, useEffect, useMemo, useCallback, useRef} from 'react';
import {Id as ToastId, toast} from 'react-toastify';
import {useLocalStorage} from 'usehooks-ts';
import {NotificationContent} from '@/components/Bookmark/NotificationContent';
import {DEFAULT_TOAST_OPTIONS} from '@/components/ToastContainer';
import {CEVENT_DISLIKE_DRUG, CEVENT_LIKE_DRUG, DlvLikeDrug} from '@/constants';
import {useUser, useDlvDrugInfo, ICurrentDrug} from '@/hooks';
import useUserData from '@/hooks/useUserData';
import {DrugsSavedByUserResult} from '@/interfaces';
import {
  addDrugsSavedByUserCookie,
  deleteDrugsSavedByUserCookie,
  convertToResults,
} from '@/utils';
import {customEvent} from '@/utils/gtm';

interface BookmarkToastHook {
  showBookmarkToast: () => void;
  closeBookmarkToast: () => void;
}

/**
 * A custom hook that manages the display and dismissal of bookmark-related toast notifications.
 * 
 * @example
 * ```tsx
 * const { showBookmarkToast, closeBookmarkToast } = useBookmarkToast();
 * 
 * // Show the toast notification
 * showBookmarkToast();
 * 
 * // Dismiss the toast notification
 * closeBookmarkToast();
 * ```
 * 
 * @remarks
 * - Uses react-toastify for toast notifications
 * - Maintains a reference to the current toast ID to prevent multiple toasts from stacking
 * - Automatically dismisses any existing toast before showing a new one
 * - The toast content is rendered using the NotificationContent component
 */
const useBookmarkToast = (): BookmarkToastHook => {
  const toastIdRef = useRef<ToastId | null>(null);

  const closeBookmarkToast = useCallback(() => {
    if (toastIdRef.current) {
      toast.dismiss(toastIdRef.current);
      toastIdRef.current = null;
    }
  }, []);

  const showBookmarkToast = useCallback(() => {
    if (toastIdRef.current) {
      toast.dismiss(toastIdRef.current);
    }
    toastIdRef.current = toast(
      <NotificationContent onIconClick={closeBookmarkToast} onCloseIconClick={closeBookmarkToast} />,
      DEFAULT_TOAST_OPTIONS,
    );
  }, [closeBookmarkToast]);

  return {showBookmarkToast, closeBookmarkToast};
};

export function useBookmarkPDP(currentDrug: ICurrentDrug) {
  const {
    userData: {bookmarks},
    setUserData,
  } = useUserData();
  const [paywallSubmitted] = useLocalStorage('user_submitted_paywall', false);
  const [isFilled, setIsFilled] = useState(false);
  const [renderModal, setRenderModal] = useState(false);
  const {logged} = useUser();
  const dlvData = useDlvDrugInfo();
  const {showBookmarkToast, closeBookmarkToast} = useBookmarkToast();

  const eventData: DlvLikeDrug = useMemo(
    () => ({
      ...dlvData,
    }),
    [dlvData],
  );

  const addBookmark = useCallback(() => {
    customEvent<DlvLikeDrug>(CEVENT_LIKE_DRUG, eventData);

    const newBookmark = {
      label_title: currentDrug.drugName,
      slug: currentDrug.slug,
      objectID: currentDrug.setId,
      labeler: currentDrug.labelerName,
    } as DrugsSavedByUserResult;

    setUserData((prevValue) => ({
      ...prevValue,
      bookmarks: addDrugsSavedByUserCookie(bookmarks, newBookmark, false),
    }));

    setIsFilled(true);
    if (!logged) {
      showBookmarkToast();
    }
  }, [
    eventData,
    currentDrug,
    setUserData,
    logged,
    bookmarks,
    showBookmarkToast,
  ]);

  const removeBookmark = useCallback(() => {
    customEvent<DlvLikeDrug>(CEVENT_DISLIKE_DRUG, eventData);

    const bookmarkToDelete = {
      label_title: currentDrug.drugName,
      slug: currentDrug.slug,
      objectID: currentDrug.setId,
      labeler: currentDrug.labelerName,
    } as DrugsSavedByUserResult;

    setUserData((prevValue) => ({
      ...prevValue,
      bookmarks: deleteDrugsSavedByUserCookie(bookmarks, bookmarkToDelete),
    }));

    setIsFilled(false);
    closeBookmarkToast();
  }, [eventData, currentDrug, setUserData, closeBookmarkToast, bookmarks]);

  const toggleFavorite = () => {
    if (isFilled) {
      removeBookmark();
    } else {
      addBookmark();
    }
  };

  const handleClick = () => {
    if (logged || paywallSubmitted) {
      toggleFavorite();
    } else {
      setRenderModal(true);
    }
  };

  const closeModal = () => {
    setRenderModal(false);
    if (paywallSubmitted) toggleFavorite();
  };

  const bookmarksArr = useMemo<DrugsSavedByUserResult[]>(
    () => (bookmarks ? convertToResults(bookmarks) : []),
    [bookmarks],
  );

  useEffect(() => {
    const isIncluded: boolean = bookmarksArr.reduce(
      (status, bookmark) =>
        bookmark.label_title === currentDrug.drugName || status,
      false,
    );
    setIsFilled(isIncluded);
  }, [currentDrug.drugName, bookmarksArr]);

  return {
    isFilled,
    toggleFavorite,
    handleClick,
    closeModal,
    renderModal,
  };
}
