import {Modal} from '@prescriberpoint/ui';
import {FC} from 'react';
import Share, {IShareProps} from '.';

export interface IDesktopShareProps extends IShareProps {
  isModalOpen: boolean;
  isMobile?: boolean;
  onModalClose: () => void;
}

const DesktopShare: FC<IDesktopShareProps> = ({
  isModalOpen,
  onModalClose,
  isMobile = false,
  ...props
}) => (
  <Modal
    isOpen={isModalOpen}
    closeModal={onModalClose}
    wrapperClassName='!h-dvh'
    className='!max-w-[420px]'
    bodyClassName='!pt-0 !pb-5'
    position={isMobile ? 'side' : 'center'}
    showCancel={false}
    showConfirm={false}>
    <Share {...props} />
  </Modal>
);

export default DesktopShare;
