import {ToastNotification} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {Check, X} from 'lucide-react';
import {FC, MouseEventHandler} from 'react';
import {useAuthPath} from '@/hooks';

interface NotificationContentProps {
  onIconClick?: MouseEventHandler<SVGSVGElement>;
  onCloseIconClick?: MouseEventHandler<SVGSVGElement>;
}

export const NotificationContent: FC<NotificationContentProps> = ({
  onIconClick,
  onCloseIconClick,
}) => {
  const {signupPath} = useAuthPath();

  const rightIconClassname = clsx(
    'ppt-text-white',
    onIconClick ? 'ppt-cursor-pointer' : null,
  );

  const iconClassName = clsx(
    'ppt-text-white',
    onIconClick ? 'ppt-cursor-pointer' : null,
  );

  return (
    <ToastNotification
      text='Saved to bookmarks'
      rightIcon={
        <X
          size={24}
          className={rightIconClassname}
          onClick={onCloseIconClick}
        />
      }
      icon={
        <Check size={24} className={iconClassName} onClick={onIconClick} />
      }
      button={{
        id: 'view_all',
        text: 'View All',
        hrefKey: signupPath,
      }}
    />
  );
};
