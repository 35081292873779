import {useEffect} from 'react';
import {useCookies} from 'react-cookie';
import useUser from './useUser';
import {
  COOKIE_CONSENT_COOKIE,
  INTERNAL_TRAFFIC_COOKIE,
  NPI_COOKIE,
} from '@/constants/cookies';
import {IS_PRODUCTION} from '@/constants/global';
import {getClarity} from '@/services/clarity';

export const useClarityInit = (isEnabled: boolean) => {
  const {logged, email} = useUser();
  const [cookies] = useCookies([
    INTERNAL_TRAFFIC_COOKIE,
    NPI_COOKIE,
    COOKIE_CONSENT_COOKIE,
  ]);
  const clarity = getClarity();
  const hasRevokedConsent = cookies?.[COOKIE_CONSENT_COOKIE] === 'false';

  // send identify event when user is logged in
  useEffect(() => {
    if (isEnabled && IS_PRODUCTION && logged && clarity) {
      clarity.identify(email, {});
    }
  }, [clarity, logged, email, isEnabled]);

  // set internal_traffic and npi_verified values
  useEffect(() => {
    if (!IS_PRODUCTION || !isEnabled || !clarity) return;

    if (cookies[INTERNAL_TRAFFIC_COOKIE] === 'true') {
      clarity.setTag('internal_traffic', 'true');
    } else {
      clarity.setTag('internal_traffic', 'false');
    }

    if (cookies[NPI_COOKIE]) {
      clarity.setTag('npi_verified', 'true');
    } else {
      clarity.setTag('npi_verified', 'false');
    }
  }, [cookies, clarity, isEnabled]);

  useEffect(() => {
    if (IS_PRODUCTION && clarity && !hasRevokedConsent && isEnabled) {
      clarity.consent();
    }
  }, [clarity, hasRevokedConsent, isEnabled]);
};
