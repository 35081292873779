/* eslint-disable @next/next/no-img-element */
import {IconButton, Text, Icon} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC} from 'react';
import {useUserAgentContext} from '@/context/UserAgentContext';

export interface IRegistrationModalHeaderProps {
  resourceImg?: string;
  setIsOpen: (_value: boolean) => void;
  isGenericVersion?: boolean;
  benefit?: string;
  suggestion?: string;
}

const RegistrationModalHeader: FC<IRegistrationModalHeaderProps> = ({
  resourceImg,
  isGenericVersion,
  setIsOpen,
  benefit = 'PrescriberPoint offers free resources and support for 35,000+ therapies',
  suggestion = 'Save this brand and other FDA-approved drug labels to view later',
}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  const imgSize = isMobileOrTablet ? 64 : 84;

  return (
    <div className={clsx(!isGenericVersion && 'bg-theme-lighter-alt')}>
      <div className='flex justify-end pl-6 pr-[15px] pt-6 md:pl-8 md:pr-[23px] md:pt-8'>
        <IconButton
          size='md'
          onClick={() => {
            setIsOpen(false);
          }}
          id='cancel_modal'
          icon={
            <Icon
              name='Cancel'
              className='flex h-[22px] w-[22px] text-theme-dark'
            />
          }
        />
      </div>
      {isGenericVersion ? (
        <div
          className={clsx('flex flex-col', {
            'space-y-6 md:space-y-8': !resourceImg,
          })}>
          <div className='flex flex-col items-center justify-center space-y-4 px-6 md:space-y-5 md:px-8'>
            <img
              src='https://cdn.prescriberpoint.com/assets/images/logo1x1.svg'
              alt='logo'
              width={imgSize}
              height={imgSize}
            />
            <Text as='body-md' className='text-center' weight='regular'>
              {benefit}
            </Text>
          </div>
          {resourceImg ? (
            <figure className='m-0 flex h-auto w-full justify-center rounded-md p-0 pt-4'>
              {/* // eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={resourceImg}
                alt='registration modal'
                className='w-60 rounded-t-sm shadow-[0_10px_20px_-1px_rgba(0,0,0,0.5)] md:w-72'
              />
            </figure>
          ) : null}
          <div className='flex flex-col items-center space-y-2 bg-theme-lighter-alt p-6 text-center md:p-8'>
            <Text
              as={isMobileOrTablet ? 'body-xs' : 'body-sm'}
              weight='bold'
              casing='title'
              className='!text-blue-700'>
              CREATE A FREE ACCOUNT
            </Text>
            <Text
              as={isMobileOrTablet ? 'headline-sm' : 'headline-md'}
              weight='bold'>
              {suggestion}
            </Text>
          </div>
        </div>
      ) : (
        <div
          className={clsx(
            'flex flex-col items-center space-y-10 overflow-hidden p-6 text-center md:p-8',
            {'pb-0 md:pb-0': !!resourceImg},
          )}>
          <div className='flex flex-col items-center space-y-2'>
            <Text
              as={isMobileOrTablet ? 'body-xs' : 'body-sm'}
              weight='bold'
              casing='title'
              className='!text-blue-700'>
              GET UNLIMITED ACCESS
            </Text>
            <Text
              as={isMobileOrTablet ? 'headline-sm' : 'headline-md'}
              weight='bold'>
              Access unlimited resources and stay updated on Xeljanz by
              registering your email
            </Text>
          </div>
          {resourceImg ? (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              src={resourceImg}
              alt=''
              className='w-60 shadow-[0_10px_20px_-1px_rgba(0,0,0,0.5)] md:w-72'
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default RegistrationModalHeader;
