import {Modal, Text, useIsMobileOrTablet} from '@prescriberpoint/ui';
import Image from 'next/image';
import {FC} from 'react';

interface InterstitialModalProps {
  isOpen: boolean;
  resourceName: string;
  brandName: string;
  shareLink: string;
  confirmLeave: () => void;
  goBack: () => void;
}

const InterstitialModal: FC<InterstitialModalProps> = ({
  isOpen,
  resourceName,
  brandName,
  shareLink,
  confirmLeave,
  goBack,
}) => {
  const isMobileOrTablet = useIsMobileOrTablet();

  const onConfirmLeave = () => {
    if (confirmLeave) {
      confirmLeave();
    }

    window.open(shareLink, '_blank');
  };
  return isOpen ? (
    <Modal
      closeModal={() => {}}
      closeOnClickOutside={false}
      showCloseButton={false}
      cancelText='Go Back'
      wrapperClassName='!h-dvh'
      confirmText='Continue'
      onConfirm={onConfirmLeave}
      onCancel={goBack}
      isOpen={isOpen}
      className='md:w-[666px] md:max-w-[98%]'>
      <div className='flex flex-col items-center justify-center space-y-10 py-6 md:py-10'>
        <Image
          src='https://cdn.prescriberpoint.com/assets/images/logo.svg'
          alt='Prescriber Point Logo'
          width={isMobileOrTablet ? 266 : 321}
          height={isMobileOrTablet ? 40 : 48}
        />
        <div className='flex w-full flex-col space-y-6'>
          <Text
            as={isMobileOrTablet ? 'headline-sm' : 'headline-md'}
            className='text-center'>
            You are now leaving PrescriberPoint.com
          </Text>
          <Text as='body-md' className='text-center'>
            View {resourceName} on {brandName} website.
          </Text>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default InterstitialModal;
