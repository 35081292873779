import {DropdownOption, IComboBoxOption} from '@prescriberpoint/ui';
import { useSearchParams } from 'next/navigation';
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useCookies} from 'react-cookie';
import {NPI_COOKIE} from '@/constants/cookies';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useCurrentDrug, useEnhanced} from '@/hooks';
import useIndications from '@/hooks/useIndications';
import {IndicationOption} from '@/interfaces/patientIndication';
import {ICoverageCarrier, ICoverageSteps} from '@/models/coverage';
import {CoverageCarrierDetailsModel} from '@/models/coverageModel';
import {StepTherapyMutation} from '@/mutations/useStepTherapyLogs';
import {IEmployerListOption} from '@/queries';

interface CoverageRestrictionsProviderProps {
  children: ReactNode;
  isForYou?: boolean;
}

export enum CoverageRestrictionStepEnum {
  EmployerSearch = 'employer',
  PayerSearch = 'payer',
}

export interface CoverageResult {
  employer?: string;
  payerName: string;
  planType: string;
  channel: string;
  carrier?: ICoverageCarrier | CoverageCarrierDetailsModel;
  formularyId?: number;
  hasPaRestrictions?: boolean;
  hasQuantityLimits?: boolean;
  hasSpecialtyPharmacy?: boolean;
  hasStepTherapy?: boolean;
}

interface GeneralData {
  currentStep: number;
  setCurrentStep: Dispatch<number>;
}

interface FormErrors {
  channel?: string;
  planType?: string;
  employer?: string;
}

export interface IDrugInteractionContext {
  alreadyHadNPIValue: boolean;
  currentSearchType: string;
  isLoadingPatientIndication: boolean;
  isModalOpen: boolean;
  isForYou: boolean;
  isAssistanceProgramsModalOpen: boolean;
  paRequirements: ICoverageSteps[];
  drugIndications: IndicationOption[];
  drugDosages: IndicationOption[];
  selectedIndication?: IndicationOption;
  selectedDosage?: IndicationOption;
  therapies: ICoverageSteps[];
  coverageRestrictionTitle: string;
  submittedCoverage?: StepTherapyMutation;
  showFinancialEnrollment: boolean;
  isFetchedPatientIndication: boolean;
  setCoverageRestrictionTitle: Dispatch<SetStateAction<string>>;
  setSubmittedCoverage: Dispatch<
    SetStateAction<StepTherapyMutation | undefined>
  >;
  closeModal: () => void;
  reset: () => void;
  resetInsuranceForm: () => void;
  resetEmployerForm: () => void;
  setSelectedIndication: Dispatch<SetStateAction<IndicationOption | undefined>>;
  setSelectedDosage: Dispatch<SetStateAction<IndicationOption | undefined>>;
  setCurrentSearchType: (v: string) => void;
  setIsModalOpen: (bool: boolean) => void;
  setIsAssistanceProgramsModalOpen: (bool: boolean) => void;
  setPaRequirements: Dispatch<SetStateAction<ICoverageSteps[]>>;
  setTherapies: Dispatch<SetStateAction<ICoverageSteps[]>>;
  openModal: () => void;
  result?: CoverageResult;
  setResult: Dispatch<SetStateAction<CoverageResult | undefined>>;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  selectedEmployer: IEmployerListOption | null;
  setSelectedEmployer: Dispatch<IEmployerListOption | null>;
  showForm: boolean;
  setShowForm: Dispatch<boolean>;
  showEmployerForm: boolean;
  setShowEmployerForm: Dispatch<boolean>;
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<boolean>;
  searchByPayer: boolean;
  setSearchByPayer: Dispatch<boolean>;
  firstInputChange: boolean;
  setFirstInputChange: Dispatch<boolean>;
  formExpanded: boolean;
  setFormExpanded: Dispatch<boolean>;
  showCoverageModalReload: boolean;
  setShowCoverageModalReload: Dispatch<boolean>;
  healthPlan: IComboBoxOption | null;
  setHealthPlan: Dispatch<IComboBoxOption | null>;
  planType: DropdownOption | undefined;
  setPlanType: Dispatch<DropdownOption | undefined>;
  insuranceChannel: DropdownOption | undefined;
  setInsuranceChannel: Dispatch<DropdownOption | undefined>;
  generalData: GeneralData;
  setAlreadyHadNPIValue: Dispatch<boolean>;
  validateForm: () => boolean;
  formErrors: FormErrors | null;
  setFormErrors: Dispatch<SetStateAction<FormErrors | null>>;
  resetFormErrors: () => void;
  setCurrentStep: Dispatch<number>;
}

const defaultState: IDrugInteractionContext = {
  alreadyHadNPIValue: false,
  coverageRestrictionTitle: '',
  currentSearchType: CoverageRestrictionStepEnum.EmployerSearch,
  submittedCoverage: undefined,
  isLoadingPatientIndication: false,
  isAssistanceProgramsModalOpen: false,
  isFetchedPatientIndication: false,
  isModalOpen: false,
  isForYou: false,
  paRequirements: [],
  drugIndications: [],
  selectedIndication: undefined,
  selectedDosage: undefined,
  drugDosages: [],
  therapies: [],
  showFinancialEnrollment: false,
  setSubmittedCoverage: () => {},
  setCoverageRestrictionTitle: () => {},
  closeModal: () => {},
  reset: () => {},
  resetInsuranceForm: () => {},
  resetEmployerForm: () => {},
  setSelectedIndication: () => {},
  setSelectedDosage: () => {},
  setCurrentSearchType: () => {},
  setIsModalOpen: () => {},
  setIsAssistanceProgramsModalOpen: () => {},
  setPaRequirements: () => {},
  setTherapies: () => {},
  openModal: () => {},
  result: undefined,
  setResult: () => {},
  query: '',
  setQuery: () => {},
  selectedEmployer: null,
  setSelectedEmployer: () => {},
  showForm: false,
  setShowForm: () => {},
  showEmployerForm: false,
  setShowEmployerForm: () => {},
  isSubmitting: false,
  setIsSubmitting: () => {},
  searchByPayer: false,
  setSearchByPayer: () => {},
  firstInputChange: true,
  setFirstInputChange: () => {},
  formExpanded: true,
  setFormExpanded: () => {},
  showCoverageModalReload: false,
  setShowCoverageModalReload: () => {},
  healthPlan: null,
  setHealthPlan: () => {},
  planType: undefined,
  setPlanType: () => {},
  insuranceChannel: undefined,
  setInsuranceChannel: () => {},
  generalData: {
    currentStep: 1,
    setCurrentStep: () => {},
  },
  setAlreadyHadNPIValue: () => {},
  validateForm: () => false,
  formErrors: null,
  setFormErrors: () => {},
  resetFormErrors: () => {},
  setCurrentStep: () => {},
};

const {financialAssistance: sectionId} = SECTION_IDS;
const CoverageRestrictionsContext = createContext(defaultState);

export const useCoverageRestrictionsContext = () =>
  useContext(CoverageRestrictionsContext);

export const CoverageRestrictionsProvider: FC<
  CoverageRestrictionsProviderProps
> = (props) => {
  const {isForYou = false} = props;
  const {setId} = useCurrentDrug();
  const [result, setResult] = useState<CoverageResult>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssistanceProgramsModalOpen, setIsAssistanceProgramsModalOpen] =
    useState(false);
  const [currentSearchType, setCurrentSearchType] = useState<string>(
    CoverageRestrictionStepEnum.EmployerSearch,
  );
  const [selectedIndication, setSelectedIndication] =
    useState<IndicationOption>();
  const [selectedDosage, setSelectedDosage] = useState<IndicationOption>();

  const {
    drugIndications,
    drugDosages,
    isPending: isLoadingPatientIndication,
    isFetched: isFetchedPatientIndication,
  } = useIndications(selectedIndication);

  const [paRequirements, setPaRequirements] = useState<ICoverageSteps[]>([]);
  const [therapies, setTherapies] = useState<ICoverageSteps[]>([]);
  const {enhancedContent} = useEnhanced(setId, sectionId);

  const [coverageRestrictionTitle, setCoverageRestrictionTitle] = useState('');
  const [submittedCoverage, setSubmittedCoverage] =
    useState<StepTherapyMutation>();
  const [cookies] = useCookies([NPI_COOKIE]);
  const [alreadyHadNPIValue, setAlreadyHadNPIValue] = useState(false);
  const hasNpi = !!cookies[NPI_COOKIE];

  useEffect(() => {
    setAlreadyHadNPIValue(hasNpi);
  }, []);

  useEffect(() => {
    if (hasNpi && !result && !alreadyHadNPIValue) {
      setAlreadyHadNPIValue(true);
    }
  }, [hasNpi, result, alreadyHadNPIValue, setAlreadyHadNPIValue]);

  const {
    patientAssistanceProgram,
    financialAssistanceProgram,
    foundationAssistanceProgram,
  } = enhancedContent ?? {};

  const params = useSearchParams();
  // Check for health plan id that is needed for query coverage to run
  const isFromQuery = Boolean(params?.get('i'));

  /** General Data */
  const [currentStep, setCurrentStep] = useState<number>(1);

  /** Form Data */
  const [query, setQuery] = useState<string>('');
  const [selectedEmployer, setSelectedEmployer] =
    useState<IEmployerListOption | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [showEmployerForm, setShowEmployerForm] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(isFromQuery);
  const [searchByPayer, setSearchByPayer] = useState(false);
  const [firstInputChange, setFirstInputChange] = useState(true);
  const [formExpanded, setFormExpanded] = useState(true);
  const [showCoverageModalReload, setShowCoverageModalReload] = useState(false);
  const [healthPlan, setHealthPlan] = useState<IComboBoxOption | null>(null);
  const [planType, setPlanType] = useState<DropdownOption>();
  const [insuranceChannel, setInsuranceChannel] = useState<DropdownOption>();
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const resetFormErrors = useCallback(() => {
    setFormErrors(null);
  }, []);

  const resetGeneralForm = () => {
    setFirstInputChange(true);
    setShowForm(false);
    setShowEmployerForm(false);
    setIsSubmitting(false);
    setCoverageRestrictionTitle('');
    setCurrentStep(1);
  };

  const resetInsuranceForm = useCallback(() => {
    resetGeneralForm();
    setHealthPlan(null);
    setPlanType(undefined);
    setInsuranceChannel(undefined);
    setCoverageRestrictionTitle('');
    setResult(undefined);
    setFormExpanded(true);
    resetFormErrors();
  }, []);

  const resetEmployerForm = useCallback(() => {
    resetGeneralForm();
    setQuery('');
    setSelectedEmployer(null);
  }, []);

  const reset = useCallback(() => {
    resetInsuranceForm();
    resetEmployerForm();

    setCurrentSearchType(CoverageRestrictionStepEnum.EmployerSearch);
    setSubmittedCoverage(undefined);
    setPaRequirements([]);
    setQuery('');
    setTherapies([]);
    setSelectedIndication(drugIndications[0]);
    setSelectedDosage(drugDosages[0]);
    setResult(undefined);
    setCurrentStep(1);
    resetFormErrors();
  }, [resetInsuranceForm, resetEmployerForm, drugIndications, drugDosages]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    reset();
  }, [reset]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (
      !isFetchedPatientIndication &&
      drugIndications.length &&
      drugDosages.length
    ) {
      return;
    }

    if (selectedIndication) {
      setSelectedDosage(drugDosages[0]);
    } else {
      setSelectedIndication(drugIndications[0]);
    }
  }, [
    selectedIndication,
    drugDosages,
    drugIndications,
    isFetchedPatientIndication,
  ]);

  useEffect(() => {
    setFormExpanded(true);
  }, [currentSearchType]);

  const generalData = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
    }),
    [currentStep],
  );

  const validateForm = useCallback(() => {
    const requiredFields = [
      {
        field: insuranceChannel,
        name: 'channel',
        message: 'You need to enter the channel ',
      },
      {
        field: planType,
        name: 'planType',
        message: 'You need to enter the plan type',
      },
    ];

    let isValid = true;
    requiredFields.forEach(({field, name, message}) => {
      if (!field) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: message,
        }));
        isValid = false;
      }
    });

    if (!isValid) {
      return false;
    }
    resetFormErrors();
    return true;
  }, [insuranceChannel, planType]);

  const values = useMemo(
    () => ({
      alreadyHadNPIValue,
      currentSearchType,
      isLoadingPatientIndication,
      isAssistanceProgramsModalOpen,
      isModalOpen,
      isForYou,
      paRequirements,
      drugIndications,
      drugDosages,
      selectedIndication,
      selectedDosage,
      therapies,
      coverageRestrictionTitle,
      submittedCoverage,
      showFinancialEnrollment:
        !!patientAssistanceProgram ||
        !!financialAssistanceProgram ||
        !!foundationAssistanceProgram,
      isFetchedPatientIndication,
      setSubmittedCoverage,
      setCoverageRestrictionTitle,
      closeModal,
      reset,
      resetInsuranceForm,
      resetEmployerForm,
      setSelectedIndication,
      setSelectedDosage,
      setCurrentSearchType,
      setIsModalOpen,
      setIsAssistanceProgramsModalOpen,
      setPaRequirements,
      setTherapies,
      openModal,
      result,
      setResult,
      query,
      setQuery,
      selectedEmployer,
      setSelectedEmployer,
      showForm,
      setShowForm,
      showEmployerForm,
      setShowEmployerForm,
      isSubmitting,
      setIsSubmitting,
      searchByPayer,
      setSearchByPayer,
      firstInputChange,
      setFirstInputChange,
      formExpanded,
      setFormExpanded,
      showCoverageModalReload,
      setShowCoverageModalReload,
      healthPlan,
      setHealthPlan,
      planType,
      setPlanType,
      insuranceChannel,
      setInsuranceChannel,
      generalData,
      setAlreadyHadNPIValue,
      validateForm,
      formErrors,
      setFormErrors,
      resetFormErrors,
      setCurrentStep,
    }),
    [
      alreadyHadNPIValue,
      closeModal,
      coverageRestrictionTitle,
      currentSearchType,
      drugDosages,
      drugIndications,
      financialAssistanceProgram,
      firstInputChange,
      formExpanded,
      showCoverageModalReload,
      foundationAssistanceProgram,
      generalData,
      healthPlan,
      insuranceChannel,
      isAssistanceProgramsModalOpen,
      isFetchedPatientIndication,
      isLoadingPatientIndication,
      isModalOpen,
      isSubmitting,
      isForYou,
      paRequirements,
      patientAssistanceProgram,
      planType,
      query,
      reset,
      resetEmployerForm,
      resetInsuranceForm,
      setShowCoverageModalReload,
      result,
      searchByPayer,
      selectedDosage,
      selectedEmployer,
      selectedIndication,
      showForm,
      showEmployerForm,
      submittedCoverage,
      therapies,
      setAlreadyHadNPIValue,
      validateForm,
      formErrors,
      setFormErrors,
      resetFormErrors,
      setCurrentStep,
    ],
  );

  return <CoverageRestrictionsContext.Provider value={values} {...props} />;
};
